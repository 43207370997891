<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label"
              for="shipment"
            >Sales Shipment</label>
            <div class="uk-form-controls">
              <input
                id="shipment"
                v-model="metaFilter.code"
                class="uk-input"
                type="text"
                placeholder="Cari sales shipment"
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label"
              for="jumlah"
            >Jumlah (ekor)</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <input
                    id="tail_start"
                    v-model="metaFilter.total_tail_start"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <input
                    id="tail_end"
                    v-model="metaFilter.total_tail_end"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label"
              for="cari"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="cari"
                v-model="metaFilter.search"
                class="uk-input"
                type="text"
                placeholder="Cari kode/nama kandang"
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label"
              for="status"
            > Status </label>
            <div class="uk-form-controls">
              <multiselect
                id="status"
                v-model="statusChoose"
                v-validate="'required'"
                name="status"
                label="name"
                track-by="name"
                placeholder="Semua"
                :options="panenstatus"
                open-direction="bottom"
                :loading="isLoading"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
              />
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label"
              for="tanggal_diubah"
            >Tanggal Panen</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinStart"
                    v-model="metaFilter.harvest_date_start"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinEnd"
                    v-model="metaFilter.harvest_date_end"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import Datepicker from "vuejs-datepicker"
import { dateString } from "@/utils/formater"
import { isCanAccess } from "@/utils/auth"

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      statusChoose: "",
      isLoading: false,
      metaFilter: {
        code: "",
        total_tail_start: "",
        total_tail_end: "",
        search: "",
        status: "",
        harvest_date_start: "",
        harvest_date_end: ""
      },
      panenstatus: [
        { name: "Completed", value: "completed" },
        { name: "Confirmed", value: "confirmed" },
        { name: "Rejected", value: "rejected" },
        { name: "Draft", value: "draft" }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meta: "harvestRequest/meta"
    })
  },
  watch: {
    "metaFilter.harvest_date_start"() {
      this.metaFilter.harvest_date_start = this.dateFormatFilter(this.metaFilter.harvest_date_start)
    },
    "metaFilter.harvest_date_end"() {
      this.metaFilter.harvest_date_end = this.dateFormatFilter(this.metaFilter.harvest_date_end)
    }
  },
  async mounted() {
    this.clearFilter()
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapMutations({
      setMeta: "harvestRequest/SET_META"
    }),
    handleFind() {
      this.metaFilter.status = this.statusChoose ? (this.statusChoose.value ? this.statusChoose.value : "") : ""
      this.metaFilter.page = 1
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      const app = this.metaFilter
      this.statusChoose = ""
      app.code = "",
      app.total_tail_start = "",
      app.total_tail_end = "",
      app.search = "",
      app.status = "",
      app.harvest_date_start = "",
      app.harvest_date_end = ""
      await this.handleFind()
    }
  }
}
</script>
