<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterPanen />
      <TablePanen />
    </div>
  </div>
</template>
<script>
import FilterPanen from './filter'
import TablePanen from './table'

export default {
  components: {
    FilterPanen,
    TablePanen
  }
}
</script>
