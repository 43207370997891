<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <TableHeader />
        <template v-if="!is_loading">
          <tbody v-if="harvest.meta.total_count > 0">
            <tr
              v-for="(data, i) in harvest.data"
              :key="i"
            >
              <td>{{ data.code || "-" }}</td>
              <td>{{ data.log_cycle_code || "-" }}</td>
              <td>{{ data.cage_name || "-" }}</td>
              <td>{{ formatDate(data.harvest_date) || "-" }}</td>
              <td>{{ thousandSeparator(data.number_of_tail) || "0" }}</td>
              <td>{{ formatDate(data.updated_at) || "-" }}</td>
              <td>
                <div :class="data.status == 'completed' || data.status == 'confirmed' ? 'status-success' : data.status == 'rejected' ? 'status-danger' : 'status-warning'">
                  {{ setCapitalize(data.status) }}
                </div>
              </td>
              <td>
                <div :class="data.is_done == 1 ? 'status-success' : 'status-warning'">
                  {{ setCapitalize(data.is_done == '1' ? "Sudah" : "Belum" ) }}
                </div>
              </td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`edit`, `Peternakan`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-left img-click"
                  @click="showDetail(data.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="10"
          />
        </template>
        <template v-else>
          <loading-table :colspan="10" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="harvest.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Pagination from "@/components/globals/Pagination"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { STATUS } from "@/utils/constant"
import { dateUtcParanjeString, thousandSeparator } from "@/utils/formater"
import { PREFIX_IMAGE } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"
import TableHeader from "./tableHeader"

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    TableHeader
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      harvest: "harvestRequest/harvestRequest",
      meta: "harvestRequest/meta"
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getHarvestRequest({ ...this.meta })
      this.is_loading = false
    }
  },
  async mounted() {
    this.setMeta({
      ...this.meta,
      ["order_by"]: "code",
      ["sort_by"]: "descending"
    })
    await this.getHarvestRequest({ ...this.meta })
    this.is_loading = false
  },
  methods: {
    setCapitalize(q) {
      return q[0].toUpperCase() + q.slice(1)
    },
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    thousandSeparator(date) {
      return thousandSeparator(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getHarvestRequest: "harvestRequest/getHarvestRequest"
    }),
    ...mapMutations({
      setMeta: "harvestRequest/SET_META"
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail(id) {
      this.$router.push(`/admin/permintaan-panen/detail/${id}`)
    },
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    }
  }
}
</script>
